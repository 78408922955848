@import "https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;700;900&family=Playfair+Display&display=swap";

/* You can add global styles to this file, and also import other style files */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box; /*  so no padding or margin */
  overflow: visible;
  line-height: normal; //this single line added so that I can have heritageweb2 pages co-mingle with heritageweb1 pages
}
// html, body { height: 100vh; }
// body { margin: 0; font-family: 'Poppins', sans-serif; }

//define colors
$primary: #003365; //indigo 700
$light-primary: #c5cae9; //indigo 100
$primary-accent: #e91e63; //pink 500
$primary-text: #000000; //black
$color-grey: #707070;
$color-darkgrey: #505153;
$color-gold: #D5A200;
$background-for-text: #F1F3F4; //gray used as background for text
$background-for-image: #F7F8F9;


//V2 colors
$primary-color: 003a70;

//fonts
$my-font-family: Poppins;
$primary-header-font: 50px;
$secondary-header-font: 35px;
$mobile-secondary-header-font: 25px;
$tertirary-header-font: 15px;

@mixin primary-header {
  font-family: $my-font-family;
  font-size: $primary-header-font;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  padding: 3rem 5rem 1rem 5rem; //keep same as paragraph-format
  line-height: 3rem;
}

@mixin secondary-header {
  font-family: $my-font-family;
  font-size: $secondary-header-font;
  line-height: 35px;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  padding: 3rem 5rem 1rem 5rem; //keep same as paragraph-format
}

@mixin tertiary-header {
  font-family: $my-font-family;
  font-size: $tertirary-header-font;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
}

@mixin image-format {
  width: 100%;
  height: auto;
  border: 2px solid #ccc;
  padding: 1rem;
  gap: 0.2rem;
}

@mixin paragraph-format {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 0.2px;
  line-height: 35px;
  padding: 3rem 5rem 1rem 5rem; //keep same as the primary-header
}

//***************************************************************************
//***************************************************************************
//below is V2, starting with hero pictures and caption
//***************************************************************************
//***************************************************************************

@mixin mixin-hero-pic {
  display: grid;
  grid-template: repeat(4, 1fr);
  & > * {
    grid-area: 1 / 1;
  }
  img {
    width: 100%;
    height: auto;
    //object-fit: fill;
    //aspect-ratio: 33/9;
  }
}

@mixin mixin-hero-caption {
  @include font-playfair();
  font-size: clamp(18px, 3vw, 50px);
  background-color: #003a70;
  opacity: 0.7;
  width: 66%;
  justify-self: start; //horizontal
  align-self: center; //vertical
  padding: 2rem 0 2rem 5rem;
  color: #ffffff;
  @media (max-width: 800px) {
    padding: 0.8rem;
  }
}

//Global in Version 2
//provides the left and right indent to line up with design, optional background color can be called
@mixin body_indent($back-color: "") {
  margin-left: 5rem;
  margin-right: 5rem;
  background-color: #{$back-color};
  @media (max-width: 800px) {
    background-color: #ffffff; //
    margin: 0rem;
  }
}

@mixin font-playfair {
  font-family: "Playfair Display", serif;
  font-style: normal;
  font-weight: normal;
  font-size: clamp(20px, 3vw, 24px);
  color: $primary-color;
}

@mixin font-lato {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: clamp(16px, 3vw, 20px);
}

@mixin display-title {
  font-family: "Playfair Display", serif;
  font-style: normal;
  font-weight: normal;
  font-size: clamp(25px, 3vw, 40px);
  color: #003a70;
  padding: 3rem 0rem 3rem 1rem;
  text-align: left;
  @media (max-width: 800px) {
    padding: 2rem 0rem 2rem 1rem;
  }
}

// ABOUT US

@mixin mixin-staff-containers {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 400px));
  gap: 0rem 2rem; //row, col and affects desktop
  @media (max-width: 800px) {
    gap: 2rem 2rem; //row, col and affects mobile, fine tuning
    padding: 0;
  }
}

@mixin mixin-staff-container {
  display: grid;
  grid-template-columns: minmax(340px, 400px);
  padding: 0rem 0rem 1rem 0rem;
  @media (max-width: 800px) {
    padding: 1rem;
  }

  .head_shot {
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }

  .staff_name {
    font-family: "Playfair Display", serif;
    font-style: italic;
    font-weight: bold;
    font-size: clamp(20px, 3vw, 33px);
    color: color-gold;
  }

  .staff_title {
    font-family: "Lato", serif;
    font-style: normal;
    font-weight: bold;
    font-size: clamp(16px, 3vw, 21px);
    color: #003a70;
  }

  .staff_bio {
    font-family: "Lato", serif;
    font-style: normal;
    font-weight: bold;
    font-size: clamp(16px, 3vw, 20px);
    color: $color-grey;
    padding: 1rem 0rem 1rem 0rem;
    font-weight: 300;
  }

  .staff_email_logo {
    width: clamp(200px, 10vw, 300px);
    height: clamp(40px, 10vw, 60px);
  }
}

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
